import { animate } from "../../node_modules/popmotion"

/*
interface Project {
    title: string
    content: string
    template?: string
    style?: string

}

const connect:Project = {
    title: "SNCF Connect",
    content: "Description"
}

const assistant:Project = {
    title: "Assistant SNCF",
    content: "Description"
}
*/

let jboCore = function(){
    let onTap = function(ev:Event) {
        console.log('-----')
        console.log(ev)
    }
    console.log('yo')
    const buttons:NodeList | null = document.querySelectorAll('.nav-list-link.with-button')
    console.log( buttons)
    buttons?.forEach( (bt:Node)=> bt.addEventListener('pointerup', onTap) )
    return true
}

class Experience extends HTMLElement {
    constructor() {
      super()
      const sourced:HTMLTemplateElement | null = <HTMLTemplateElement> document.getElementById(
        "tpl-experience"
      ) // HTMLElement cast as HTMLTemplateElement
      if( sourced !== null ){
        const shadowRoot = this.attachShadow({ mode: "open" })
        shadowRoot.appendChild(sourced.content.cloneNode(true))
        }
    }
}

customElements.define('folio-experience', Experience)

